import { Component, Input, NgZone, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stockpop',
  templateUrl: './stockpop.page.html',
  styleUrls: ['./stockpop.page.scss'],
})
export class StockpopPage implements OnInit {
  @Input() value;

  onlyStock = false
  constructor(public translate: TranslateService, public popCtrl: PopoverController, private zone: NgZone) { }

  ngOnInit() {
    this.zone.run(() => {
      this.onlyStock = this.value
    })
  }
  selectType() {
    console.log('change', this.onlyStock)
    // if (this.onlyStock !== null)
    this.popCtrl.dismiss(this.onlyStock)
  }
}
